<template>
  <div
    v-if="category"
    :class="{ 'w-100 d-flex flex-column justify-center text-center': centered }"
  >
    <v-img
      v-if="headerImage"
      :src="headerImage"
      :height="height"
      class="elevation-3 mb-4"
      @load="loaded = true"
      :alt="category.name"
    ></v-img>
    <div
      v-if="!hideTitle"
      class="d-flex align-center flex-row flex-wrap default--text mb-0 mb-sm-2"
      :class="{ 'justify-center': centered }"
    >
      <h1 class="text-h4 text-sm-h1" v-html="title" :class="titleClasses"></h1>
      <v-icon v-if="titleIcon" x-large class="ml-2 mb-4" :class="iconClass">{{
        titleIcon
      }}</v-icon>
      <strong class="ml-4 mt-2" v-if="count > 0">
        ({{ count }} prodotti)
      </strong>
    </div>
    <p
      class="text-caption text-sm-h5"
      @click="clicked"
      v-if="subtitle"
      v-html="subtitle"
    ></p>
  </div>
</template>
<style scoped lang="scss">
h1 {
  line-height: 3rem !important;
  @media #{map-get($display-breakpoints, 'xs-only')} {
    p {
      margin-bottom: 8px;
    }
  }
}
p {
  line-height: 20px !important;
}
p > a {
  color: var(--v-default-base) !important;
}
</style>
<script>
import clickHandler from "~/mixins/clickHandler";

import get from "lodash/get";
import has from "lodash/has";
export default {
  name: "CategoryTitle",
  props: {
    count: { type: Number, required: false },
    category: { type: Object, required: true },
    selectedCategory: { type: Object, required: false },
    height: { type: String },
    centered: { type: Boolean, default: false },
    titleIcon: { type: String, default: "" },
    iconClass: { type: String, default: "" }
  },
  mixins: [clickHandler],
  data: function() {
    return { loaded: false };
  },
  computed: {
    title() {
      let c = this.selectedCategory ? this.selectedCategory : this.category;
      return get(c, "metaData.category_info.TITLE", c.name);
    },
    titleClasses() {
      let setBold = !has(
        this.selectedCategory || this.category,
        "metaData.category_info.TITLE"
      );
      return {
        "font-weight-bold": setBold
      };
    },
    subtitle() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    },
    headerImage() {
      let prop =
        "metaData.category_info." +
        (!this.$vuetify.breakpoint.smAndDown
          ? "HEADER_IMAGE"
          : "HEADER_IMAGE_MOBILE");
      return get(
        this.category,
        prop,
        get(this.category, "metaData.category_info.HEADER_IMAGE")
      );
    },
    hideTitle() {
      return get(this.category, "metaData.category_info.HIDE_TITLE", false);
    }
  }
};
</script>
